import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import '../css/projectScroll.css'
import {Link} from 'react-router-dom';


const ProjectScroll = ({projectsContent}) => {

    const slides = projectsContent.map((project, index) => {
        
        return <Carousel.Item key={index}>
                    
                <Link to={`/${project.route}`}>
                    <img className="d-block w-100 slide-image" src={process.env.PUBLIC_URL + project.image} alt={project.title}/>
                    </Link>
                </Carousel.Item>
    });

    
    return (
        
        <div id="project-scroll" className="dissolve-appear3">
            <Carousel indicators={false}>
                {slides}
            </Carousel>
        </div>
    )

};

export default ProjectScroll;