import React from 'react';
import NavBar from './NavBar';
import ProjectChoice from './ProjectChoice'
import '../css/project.css';

const Projects = ({projectsContent}) => {

    const projectNodes = projectsContent.map((node, index) => {

        return <ProjectChoice node={node} key={index}/>
    });

    return (
        <div className="scroll-container">
            <main id="main-reverser">
                <NavBar />
                <div id="centre-row">
                    {projectNodes}
                </div>
            </main>
        </div>
)
}

export default Projects;
