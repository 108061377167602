import React from 'react';
import {Link} from 'react-router-dom';

const ProjectChoice = ({node}) => {

    return(
        <Link to={`/${node.route}`}>
            <div className="project-choicebox frost-box navbar-link dissolve-appear3">
                <span className="project-choice-title">{node.title}</span>
                <img src={process.env.PUBLIC_URL + node.image} alt={node.title} className="project-choice-image"/>
                <span className="project-choice-type">{node.category}</span>
            </div>
        </Link>
    );
};

export default ProjectChoice;