import React from 'react';
import NavBar from './NavBar';
import '../css/infoPanel.css'

const SingleProject = ({project}) => {

    const techNodes = project.techs.reduce((acccum, tech) => {

        return acccum + tech + "|";

    }, "|");

return (

        <main id="main-reverser">
            <NavBar />
                <div className="comment-panel info-header techs-panel dissolve-appear">
                    Built using: {techNodes}
                    </div>
            <div id="centre-row">
                <div className="info-panel frost-box dissolve-appear3">
                    <span className="info-header">{project.title}</span>
                    <div className="info-body">{project.description}</div>
                    <div className="info-body">
                        <a href={project.link} target="_blank" rel="noreferrer" className="frost-link">Visit Project</a>
                    </div>
                </div>  
                <div className="project-image-container frost-box dissolve-appear">
                    <img className="project-main-image" src={process.env.PUBLIC_URL + project.image} alt={project.title}/>
                </div>

            </div>
                <div className="comment-panel frost-box dissolve-appear3">
                    <div className="info-body">{project.comments}</div>
                </div>
        </main>
);
};

export default SingleProject;