import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Projects from '../components/Projects';
import '../css/mainContainer.css';
import './LandingContainer'
import projectsContent from '../content/projectsContent';
import LandingContainer from './LandingContainer';
import SingleProject from '../components/SingleProject';
import Error404 from '../components/Error404';
import About from '../components/About';

const MainContainer = () => {

    const [splashActive, setSplashActive] = useState(true);

    const handleMoreClick = () => {
        setSplashActive(false);

    }
    const routeNodes = projectsContent.map((project, index) => {
        return <Route path={`/${project.route}`} key={index} render={()=> <SingleProject project={project}/> } />
    });


    return (

        <Router>
            <Switch>
                <Route path="/projects" render={()=> <Projects projectsContent={projectsContent}/>} />
                <Route path="/about" component={About} />
                {routeNodes}
                <Route exact path="/" render={()=> <LandingContainer projectsContent={projectsContent} splashActive={splashActive} handleMoreClick={handleMoreClick}/>}/>
                <Route component={Error404} />
            </Switch>
        </Router>
    )


};

export default MainContainer;

