import React from 'react'
import '../css/navbar.css';
import '../css/mainContainer.css';
import ghLogo from '../assets/GitHub-Mark-Light-32px.png'
import liLogo from '../assets/In-Mono-Logo.png'
import {Link} from 'react-router-dom';

const NavBar = () => {

    return (
        <div id="navbar" className="frost-box dissolve-appear2">
            <Link to="/" className="navbar-link"><i className="fas fa-home navbar-logo"></i></Link>
            <Link to="/projects" className="navbar-link">Projects</Link>
            <Link to="/about" className="navbar-link">About me</Link>
            <a href="mailto:contact@scottanderson.one" className="navbar-link"><i className="far fa-envelope navbar-logo"></i></a>
            <a href="https://github.com/IXIXIXIXIXIXIXIXIX/" target="_blank" rel="noreferrer"><img src={ghLogo} alt="GitHub Profile" className="navbar-logo"/></a>
            <a href="https://www.linkedin.com/in/scott-anderson-ix/" target="_blank" rel="noreferrer"><img src={liLogo} alt="LinkedIn Profile" className="navbar-logo"/></a>
        </div>


    );
};

export default NavBar;