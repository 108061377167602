import React from 'react';
import NavBar from './NavBar';
import '../css/mainContainer.css'
const Error404 = () => {


   
    return (

            <main id="main-reverser">
                <NavBar />
                <div id="centre-row">
                    
                    <article id="about-main" className=" info-panel frost-box dissolve-appear">
                        <span className="info-header">
                            <h1 className="frostbox-announce-head">Error 404</h1>
                        </span>
                        <div className="info-body">
                            <p> 
                                Click a link in the Nav Bar below to find what you are looking for.
                            </p>
            
                        </div>
                    </article>
                </div>
            </main>
      
    );
}

export default Error404;