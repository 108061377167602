const projectsContent = [
    {
        title: "Space Search",
        image: "/img/nasa_scrshot.png",
        link: "http://ix-spacesearch.surge.sh/",
        route: "spacesearch",
        description: "The app gives you some random NASA pictures of the day with accompanying info alongside the ability to search NASA's image archives for whatever space-related stuff you like.",
        comments: "I built this one as part of my CodeClan journey. I was particularly focussing on gaining some new CSS skills here.",
        techs: [
            "HTML",
            "CSS",
            "JavaScript",
            "React"
        ],
        category: "webapp"
    },
    {
        title: "SkyRabble",
        image: "/img/skyrabble_ss.png",
        link: "https://skyrabble.herokuapp.com/",
        route: "skyrabble",
        description: "Search any location in the world for aircraft currently in the air, choose your players and see which plane wins the battle of wits (or more accurately, the battle of their what.three.words locations).",
        comments: "SkyRabble is the first ever group project I contributed to; completed in 6 days. Together with Christina Houston and Tom Stocker, we developed and deployed this app as part of our journey at CodeClan.",
        techs: [
            "HTML",
            "CSS",
            "JavaScript",
            "React",
            "Express",
            "MongoDB",
            "Heroku"
        ],
        category: "webapp"
    },
    {
        title: "Spend.io",
        image: "/img/spendio_ss.png",
        route: "spendio",
        link: "https://github.com/IXIXIXIXIXIXIXIXIX/spend.io",
        description: "This is a simple spending tracker app. It allows the user to set a budget, enter transactions, tag them then view them optionally filtered by various criteria.",
        comments: "Spend.io was the first project that I completed as part of the Professional Software Development course at CodeClan. It was a solo project that I completed in 6 days.",
        techs: [
            "PostgreSQL",
            "Python",
            "Flask",
            "HTML",
            "CSS"
        ],
        category: "webapp"
    },
    {
        title:"DarkSignal.one",
        image: "/img/darksignal_ss.png",
        link: "http://www.darksignal.one/",
        route: "darksignal",
        description: "Darksignal.one allows users to assign mp3 files (optionally with some text and a hyperlink) to a uniquely-generated QR code that can then be scanned with a mobile device to play the mp3 and show both the text and link.",
        comments: "Created as my final project for the CS50x course provided by HarvardX. A solo project, taking in everything I learned during CS50x and a great deal beyond. Great introduction to full-stack development.",
        techs: [
            "AWS",
            "Amazon ECs",
            "Amazon SES",
            "Apache2",
            "PostgreSQL",
            "HTML",
            "CSS",
            "JavaScript",
            "Bootstrap",
            "Python",
            "Flask",
            "Flask-SQLAlchemy"
        ],
        category: "webapp"
    },
    {
        title:"pyMenus",
        image: "/img/pyMenus_ss.png",
        link: "https://github.com/IXIXIXIXIXIXIXIXIX/pyMenus",
        route: "pymenus",
        description: "pyMenus is a collection of classes that create CLI menus and prints them to the terminal. Return either the index of the user's response or the object that the user chooses. Useful for quickly creating simple CLI user interfaces. I have made this available to use under an MIT licence.",
        comments: "I found that I was using similar CLI menu generating code in several Python projects. Rather than repeating this code, I made it general and abstracted it out into this one module.",
        techs: [
            "Python"
        ],
        category: "tool/package"
    },
    {
        title:"Rock Paper Scissors",
        image: "/img/rockpaperscissors_ss.png",
        link: "https://github.com/IXIXIXIXIXIXIXIXIX/rockpaperscissors",
        route: "rockpaperscissors",
        description: "A basic Rock Paper Scissors game implemented using Python. Expanded to optionally incorporate 5 options of 'Rock Paper Scissors Lizard Spock' as well as the traditional 3. Optionally play against the computer or against another human.",
        comments: "Just a simple one-or-two-player game with a browser-based user interface.",
        techs: [
            "Python",
            "HTML",
            "CSS"
        ],
        category: "webapp"
    },
    {
        title:"Blackjack",
        image: "/img/blackjack_ss.png",
        link: "https://github.com/IXIXIXIXIXIXIXIXIX/blackjack",
        route: "blackjack",
        description: "A basic console Blackjack game implemented in Java. For 1 to 5 players and with ASCII art representations of cards.",
        comments: "A game I programmed with the aim of getting to know my way around Java.",
        techs: [
            "Java"
        ],
        category: "console applicaton"
    },
    {
        title:"startflask",
        image: "/img/startflask_ss.png",
        link: "https://github.com/IXIXIXIXIXIXIXIXIX/startflask",
        route: "startflask",
        description: "Bash script to create some of the boilerplate code, directories and files for a simple flask app.",
        comments: "I found that I was repeating a lot of boilerplate code when setting up basic flask apps, so I wrote this script to automate the inital setup.",
        techs: [
            "Bash shell"
        ],
        category: "tool/package"
    },
    {
        title:"Hangman",
        image: "/img/hangman_ss.png",
        link: "https://github.com/IXIXIXIXIXIXIXIXIX/hangman",
        route: "hangman",
        description: "A console-based hangman game for one player, written in Python. With a built-in configurable dictionary of words to guess.",
        comments: "Just a fun project that I worked on in my spare time. I do like an old-style terminal app.",
        techs: [
            "Python"
        ],
        category: "console application"
    },
    {
        title:"Hastur",
        image: "/img/hastur_ss.png",
        link: "https://github.com/IXIXIXIXIXIXIXIXIX/hastur_cb",
        route: "hastur",
        description: "A Crypto trading bot concept; version that works with Coinbase. The fee structure makes this unprofitable. This is the main algorithm; also required is a library to interfact with the exchange.",
        comments: "I built this as a proof of concept and it was successful. However, Coinbase's fee structure makes it impractical to run in any serious capacity.",
        techs: [
            "C++"
        ],
        category: "console application"
    },
    {
        title:"Calculon",
        image: "/img/calculon_ss.png",
        link: "https://github.com/IXIXIXIXIXIXIXIXIX/calculon",
        route: "calculon",
        description: "Calculon is a command line calculator. There are many like it, but this one is mine. It's also shares name with a terrible and fictional robot actor. Created as an exercise and as an application that I use.",
        comments: "I built this as a way to explore the C++ language and to play with different ways to implement a simple idea.",
        techs: [
            "C++"
        ],
        category: "console application"
    }

];

export default projectsContent;