import React from 'react';
import '../css/infoPanel.css';
import {Link} from 'react-router-dom';

const InfoPanel = () => {

    return (
        <article id="info-panel" className=" info-panel frost-box dissolve-appear">
            <span className="info-header">
                Hello, my name is Scott Anderson.
            </span>
            <div className="info-body">
            <p> 
                I'm a full-stack developer with a STEM degree and experience in developing apps with AWS, Heroku, PostgreSQL, MongoDB, Java, Python, 
                Flask, Django, Express, React, Javascript and many other technologies.
            </p>

            <p>
                Currently I'm mostly interested in exploring ways that the online world interacts with our everyday experiences: everything from concepts like  
                dynamically-generated QR codes on physical objects linking to online experiences, all the way to blockchain tokens, fintech and cryptocurrency 
                being used to conduct real-world transactions.
            </p>

            <p>
                If you'd like to ask me any questions or you'd like to discuss potentially working together, please feel free to drop me a line. My contact details 
                can be accessed via the bar at the bottom of the screen.
            </p>

            <div className="info-link-container">
            
            <Link to="/projects" className="frost-link">View Projects</Link>
            </div>
            

            </div>

        </article>
    )


}

export default InfoPanel;