import React from 'react';
import NavBar from '../components/NavBar';
import {Link} from 'react-router-dom';
import '../css/about.css'

const About  = () => {

        return (
            <main id="main-reverser">
                <NavBar />
                <div id="centre-row">
                    
                    <article id="about-main" className=" info-panel frost-box dissolve-appear">
                        <span className="info-header">
                            A brief history of me
                        </span>
                        <div className="info-body">
                        <p> 
                            I am a full-stack software developer who has an insatiable apetite for new knowledge, experiences and problems to solve.
                            Being introduced to the world of coding at a very early age with the advent of the ZX Spectrum +3, I still have a fondness for BASIC as the first programming 
                            language that I learned.
                            More formally, my educational background is in STEM: I have a BSc(HONS) in Molecular and Cellular Biology with electives in Computer Science from The Univeristy of Glasgow. 
                        </p>

                        <p>
                            Since then, I have worked in various roles performing a variety of tasks, from building databases and investigating fraud at a bank to managing 
                            stages with large teams of technicians and artists to stage some of the biggest live events in the UK.
                        </p>

                        <p>
                            Most recently, in search of greater challenges, I have stepped up my commitment to lifelong learning by studying 
                            Computing Science and Software Development with HarvardX and CodeClan. I've you'd like to have a chat about working with me, drop me a line. My contact details 
                            are in the navbar below:
                        </p>

                        <div className="info-link-container">
            
                        <Link to="/projects" className="frost-link">View Projects</Link>
                        <Link to="/" className="frost-link">Home</Link>
                        </div>
            

            </div>

        </article>
                
                </div>
            </main>
        )


}

export default About;