import '../css/mainContainer.css';
import Splash from '../components/Splash';
import NavBar from '../components/NavBar';
import ProjectScroll from '../components/ProjectScroll';
import InfoPanel from '../components/InfoPanel';

const LandingContainer = ({projectsContent, splashActive, handleMoreClick}) => {


    if (splashActive)
    {
        return (
            <div id="main-container">
                <Splash handleMoreClick={handleMoreClick}/>
            </div>
        )
    }
    else
    {
        return (
            <main id="main-reverser">
                <NavBar />
                <div id="centre-row">
                    
                    <ProjectScroll projectsContent={projectsContent}/>
                
                    <InfoPanel />
                </div>
            </main>
        )
    }


};

export default LandingContainer;

